// Alex
import React from "react";

// Instructions if there are no events
export default function Calendar_instruction() {
    return (
        <div id="calendar_tutorial">
            You have not events due on this day. Hold and click a date on the calendar above to add an event.
        </div>
    )
}