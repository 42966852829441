import { NavLink } from "react-router-dom";
import logo from "../res/images/Logo-Vector-Graphics.svg";

export default function Login_page(props) {
    return (
        <div
            className="login"
        >
            <img src={logo}>
            
            </img>
            <hr></hr>
            <p>
                This is Sigma, an management and timetable application created for Sydney Boys Students. Press the Login button below to view the timetable. You can access the <NavLink to={'/calendar'}>Calendar</NavLink>, <NavLink to={'/quote_of_the_day'}>Quote of the Day</NavLink> and your <NavLink to={'/focus'}>Focus</NavLink> without signing in. Report any issues on the <NavLink to={'/feedback'}>feedback</NavLink> tab.
            </p>
            <hr></hr>
            <button
                title="Login to Sydney Boys High"
                className="clickable_button"
                aria-label="login button"
            
            >
                Login
            </button>
        </div>
    )
}